import React from 'react'
import { Router } from '@reach/router'
import Demo from './components/demo'
import ErrorPage from './components/ErrorPage'
import {
  GlobalError
} from 'dnb-ui-lib/components'

import LandingContent from './components/landing-content'
import Info from './components/InfoPage'

import { AuthProvider } from './context/AuthContext'

function App() {
  return (
    <AuthProvider>
      <Router>
        <Demo path="/">
          <LandingContent path="/" />
          <Info path="/info"/>
          <GlobalError default status="404" />
          <ErrorPage path="/error" />
        </Demo>
      </Router>
    </AuthProvider>
  )
}

export default App