import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import 'dnb-ui-lib/style/basis'
import 'dnb-ui-lib/style/components'
import 'dnb-ui-lib/style/themes/ui'

import { CacheProvider } from '@emotion/react'
import createEmotionCache from '@emotion/cache'
import stylisPlugin from 'dnb-ui-lib/style/stylis'

import EufemiaProvider from 'dnb-ui-lib/shared/Provider'

const emotionCache = createEmotionCache({
  key: 'demo',
  stylisPlugins: [stylisPlugin]
})

ReactDOM.render(
  <EufemiaProvider formRow={{}}>
    <CacheProvider value={emotionCache}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </CacheProvider>
  </EufemiaProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
