import React, {
  useEffect,
} from 'react';
import { useLocation } from'@reach/router'
import { parse } from 'query-string'
import {
  GlobalStatus,
  GlobalError
} from 'dnb-ui-lib/components'

function ErrorPage() {
  
  const location = useLocation()

  useEffect(() => {
    const searchParams = parse(location.search)
    const statusOne = GlobalStatus.create({
      title: searchParams.errTitle || "Error from login provider.",
      id: 'main',
      status_id: 'pf-error-msg',
      text: searchParams.errMsg || "No description of the error was sent.",
    })
    return () => {
      statusOne.remove()
    }
  }, [])
  
  return (
    <GlobalError status="500" />
  )
}

export default ErrorPage