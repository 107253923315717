import React, {
    createContext,
    useState,
    useEffect
} from 'react'

import axios from 'axios'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import { navigate } from "@reach/router"

import { DOMAIN } from '../constants'

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  
    const [authState, setAuthState] = useState({
      isAuthenticated: false
    });
    
    const logout = () => {
      axios.get(`/v1/logout`)
        .then(() => {
          console.log("Session ended. Logout complete.")
        })
        .catch(() => {
          Cookies.remove('idtc', { path: '/', domain: DOMAIN })
          console.log("Access expired. Clientside logout.")
        })
        .finally(() => {
          setAuthState({
            given_name: null,
            family_name: null,
            birthdate: null,
            isAuthenticated: false
          })
          navigate("/")
        })
    }


    useEffect(() => {
        const idToken = Cookies.get('idtc' )
        if (idToken) {
            try {
                var decoded = jwtDecode(idToken)
                if (new Date().getTime() / 1000 > decoded.exp) {
                    console.log("Removing expired Id token.")
                    Cookies.remove('idtc', { path: '/', domain: DOMAIN })
                } else {
                    setAuthState({
                        given_name: decoded.given_name,
                        family_name: decoded.family_name,
                        birthdate: decoded.birthdate,
                        isAuthenticated: true
                    })
                }
            } catch (err) {
              console.log("Removing corrupt Id token.")
              Cookies.remove('idtc', { path: '/', domain: DOMAIN })
            }
        }
    }, []);

  
    const setAuthInfo = ({ userInfo }) => {
      setAuthState({
        userInfo,
        isAuthenticated:
          userInfo && userInfo._id ? true : false
      });
    };
  
    return (
      <Provider
        value={{
          authState,
          setAuthState: authInfo => setAuthInfo(authInfo),
          logout,
        }}
      >
        {children}
      </Provider>
    );
  };

  AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
  };
  
  export { AuthContext, AuthProvider };