import React from 'react'
import styled from '@emotion/styled'

// Get Eufemia in
import { P } from 'dnb-ui-lib/elements'
import {
  Heading,
  Section,
} from 'dnb-ui-lib/components'

/** -- 2. Styles -- */

// Visual helper to limit the width inside of our layout
const WidthLimit = styled.div`
  max-width: 42rem;
  .dnb-input__input {
    max-width: 10rem;
  }
  @media (max-width: 40em) {
    textarea {
      width: 90vw;
    }
  }
`

// Custom paragraph
const IngressRaw = styled(P)`
  font-weight: var(--font-weight-medium);
  color: var(--color-emerald-green);
`
const Ingress = (props) => (
  <IngressRaw top="x-small" bottom="small" {...props} />
)

const InfoPage = () => {

    return (
      <WidthLimit>
        <Section top="medium" spacing="x-large" style_type="white">
            <Heading>Background</Heading>
            <Ingress>
            We need a test application to be able to develop and test the web authentication flows.
            </Ingress>

        </Section>
        

        <Section spacing="x-large">
            <Heading>Testable requirements</Heading>
            <Ingress>
                <li>We are able to test end to end authentication for web applications in aws.</li>
                <li>We are able to call dummy resource apis after authentication.</li>
                <li>Dummy api authorize calls based on session cookie.</li>
            </Ingress>
        </Section>

        <Section spacing="x-large" style_type="white">
            <Heading>About this app</Heading>
            <Ingress>
                Initialized with create-react-app and inpired by the Eufemia demo at
            </Ingress>
            <a href="https://eufemia.dnb.no/uilib/demos/eufemia-demo/form-demo-01/">https://eufemia.dnb.no/uilib/demos/eufemia-demo/form-demo-01/</a>
        </Section>
  
      </WidthLimit>
    )
  }

  export default InfoPage