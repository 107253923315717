import React, {
    useContext
} from 'react';
import { Helmet as Head } from 'react-helmet'
import styled from '@emotion/styled'
import { Link, navigate } from "@reach/router"

import { AuthContext } from '../context/AuthContext'

// Get Eufemia in

import {
  Heading,
  Section,
  Button,
  Space,
  GlobalStatus,
  Logo,
  Icon
} from 'dnb-ui-lib/components'
import {
  log_in,
  log_out,
  information_circled
} from 'dnb-ui-lib/icons'

const handleLogin = () => {
    window.location.href = `/v1/lambda`
}


const Header = () => {
    const { logout, authState, authState: { isAuthenticated }} = useContext(AuthContext)
    console.log("authState", authState)

    return (
        <>
            <Head>
                <html lang="en" />
                <title>Demo app</title>
            </Head>
            <HeaderSection style_type="mint-green">
            
                <HeaderTitleWrapper>
                    <span style={{fontSize: '3rem'}}>
                        <Logo size="auto" bottom="small" right="small" onClick={() => navigate("/")}/>
                    </span>  
                    <Heading size="small">DEMO web application</Heading>
                    <Link to="/info">
                        <Icon
                            icon={information_circled}
                            size="medium"
                            title="Info"
                            inherit_color={false}
                            left="large"
                        />
                    </Link>
                    <div style={{margin: "auto"}} />
                    { isAuthenticated ?
                        <Button style={{marginRight: "1em"}} data-test="logout_button" type="submit" text="Log Out" icon={log_out} onClick={logout}/>
                        : <Button style={{marginRight: "1em"}} data-test="login_button" type="submit" text="Log In" icon={log_in} onClick={handleLogin}/>
                    }
                </HeaderTitleWrapper>
            </HeaderSection>
            <GlobalStatus />
        </>
    )
}

export default Header
  

/** -- 2. Styles -- */


// set the header hight
const HeaderSection = styled(Section)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 4rem;
`
// center the h1 vertically
const HeaderTitleWrapper = styled(Space)`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`