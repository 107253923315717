import React from 'react';
import PropTypes from 'prop-types'

import Header from './Header'
import Layout from '../layout/Layout'

// Get Eufemia in

import {
  Space,
} from 'dnb-ui-lib/components'


/** -- 1. Markup -- */

// Our main component
const Demo = (props) => {
  
  return (
    <Layout>
        <Header />
        {props.children}
        <Space bottom="medium" />
    </Layout>
  )
}

Demo.propTypes = {
  children: PropTypes.node.isRequired
};

export default Demo