if (!process.env.REACT_APP_ENV) {
    process.env.REACT_APP_ENV = 'dev';
}

const environment = (process.env.REACT_APP_ENV.includes('dev') && 'dev')
    || (process.env.REACT_APP_ENV.includes('sit') && 'sit')
    || (process.env.REACT_APP_ENV.includes('uat') && 'uat')

export const DOMAIN = `.dna.${process.env.REACT_APP_ENV}.ciam.tech-03.net`
export const SIMPLIFIED_LOGIN_DOMAIN = `.ciam.tech-03.net`

const baseAuthenticationDomain = `.${environment}.ciam.tech-03.net`;

const AUTHENTICATION_DOMAIN = (() => {
    let prefix = '';
    if (process.env.REACT_APP_ENV.includes('blue') || process.env.REACT_APP_ENV.includes('green') || environment === 'dev') {
        prefix = process.env.REACT_APP_ENV + '-api';
    } else {
        prefix = 'api';
    }
    return prefix + baseAuthenticationDomain;
})()

const CLIENT_ID= (() => {
    let clientId = '';
    switch (environment) {
        case 'sit':
            clientId = '5d4619dc-87b1-413e-9794-19598354fed1';
            break;
        case 'uat':
            clientId = 'e3b3af1f-cbb9-4e8d-8d43-677339ea6814';
            break;
        case 'dev':
            clientId = '9328D6C3-C8B6-41CD-80BA-98A027A9E668';
            break;
    }
    return clientId;
})()

export { CLIENT_ID, AUTHENTICATION_DOMAIN };