import React, {
  useContext,
  useState
} from 'react';
import styled from '@emotion/styled'
import { navigate } from "@reach/router"
import axios from 'axios'

import { AuthContext } from '../context/AuthContext'

// Get Eufemia in
import { P } from 'dnb-ui-lib/elements'
import {
  Heading,
  Section,
  Button,
  Textarea,
} from 'dnb-ui-lib/components'

/** -- 2. Styles -- */

// Visual helper to limit the width inside of our layout
const WidthLimit = styled.div`
  max-width: 42rem;
  .dnb-input__input {
    max-width: 10rem;
  }
  @media (max-width: 40em) {
    textarea {
      width: 90vw;
    }
  }
`

// Custom paragraph
const IngressRaw = styled(P)`
  font-weight: var(--font-weight-medium);
  color: var(--color-emerald-green);
`
const Ingress = (props) => (
  <IngressRaw top="x-small" bottom="small" {...props} />
)

const handleErrorPath = () => {
  navigate(`/error`)
}

const handleApiCall = (setApiMessage) => {
  axios.get(`/v1/dummyresource`)
    .then(res => {
      const message = res.data.response
      setApiMessage(message)
    })
}

const LandingContent = () => {
    const { authState: { given_name, family_name, birthdate, isAuthenticated } } = useContext(AuthContext)
    const [ apiMessage, setApiMessage ] = useState("")

    return (
      <WidthLimit>
        <Section top="medium" spacing="x-large" style_type="white">
          { isAuthenticated ?
            <>
              <Heading>Welcome {given_name}</Heading>
              <Ingress>
                <p>UserInfo:</p>
                <Textarea
                  rows="4"
                  cols="40"
                  value={`given_name: ${given_name}\nfamily_name: ${family_name}\nbirthdate: ${birthdate}`}
                />
              </Ingress>
            </>
            :
            <Heading>Press the Log In button...</Heading>
          }
        </Section>
        <Section top="medium" spacing="x-large">
          <Heading bottom="small">Testing utilities</Heading>
          <Button left="x-large" type="submit" text="to errorPage" onClick={handleErrorPath}/>
          <Button right="small" left="x-large" type="submit" id="custom-triggerer" text="make api call" onClick={() => handleApiCall(setApiMessage)} />
          <div>{apiMessage}</div>
        </Section>
      </WidthLimit>
    )
  }

  export default LandingContent